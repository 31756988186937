.headerRoot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 32px;
    gap: 24px;
    padding-top: .5em;

    /* background: #9ADAE7; */
}

.filumHealthLogo {
    width: 146px;
    height: 45px;
}

.quickLinksAndSearch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;

    width: 75%;
    height: 50px;
}

.quickLinks {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    width: 372px;
    height: 50px;
}

.quickLinksIcon {
    display: flex;
    flex-direction: column;
    justify-self: center;
    
    height: 40px;
}

.searchBox {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;

    width: 500px;
    height: 40px;

    /* Primary/50 */

    background: #F5FBFD;
    /* Neutral/500 */

    border: 1px solid #64748B;
    border-radius: 4px;
}

.profileHeader {
    display: flex;
    flex-direction: row;
}

.profilePic {
    width: 45px;
    height: 45px;
}

.profileDownIcon {

    width: 10px;
    height: 10px;
    /* margin-top: 25%;
    margin-left: .2em; */
    /* Neutral/500 */
/*     
    border: 2px solid #64748B */
}