.calendarWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5em;
    border: #0D0F11 2px;
    
    /* position: absolute; */
    width: 200px;
    height: 200px;
    /* left: 47px;
    top: 16px; */
    
    background: #FFFFFF;
    /* border: 1px solid #0D0F11; */
    /* border-radius: 8px; */
}

.monthYearHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: .3em;

    width: 100%;
    height: 19px;

    /* flex: none; */
    order: 0;
    align-self: flex-end;
    flex-grow: 0;
}

.prevButton {
    width: 16px;
    height: 16px;

    display: flex;
    text-align: justify;

    /* flex: none; */
    order: 0;
    flex-grow: 0;
}

.nextButton {
    width: 16px;
    height: 16px;

    display: flex;
    text-align: justify;

    /* flex: none; */
    order: 2;
    flex-grow: 0;
}

.monthYearDisplay {
    width: 115px;
    height: 19px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 19px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #0D0F11;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.todayButton {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 4px;
    gap: 10px;

    font-size: .5em;
    justify-content: center;

    width: 38px;
    height: 16px;

    background: #9ADAE7;

    border: 1px solid #D6F0FA;
    border-radius: 2px;

    flex: none;
    order: 3;
    flex-grow: 0;
}

.todayButtonOff {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 4px;
    gap: 10px;

    font-size: .5em;
    justify-content: center;

    width: 38px;
    height: 16px;

    background: #E7EAEE;

    border: 1px solid white;
    border-radius: 2px;

    flex: none;
    order: 3;
    flex-grow: 0;
}

.weekday {
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-weight: bold;
    font-size: .75em;
    /* border: 1px solid #0D0F11; */
    width: 100%;
    height: 18px;
}

.weekdayRow {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
    padding: 0px;
    justify-content: space-around;

    width: 100%;
    height: 17px;
}

.dateBox {
    font-family: 'Inter';
    font-style: normal;
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: .85em;
    /* border: 1px solid #0D0F11; */
    width: 100%;
    height: 1.7em;
}

.dateRow {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding-top: .5em;

    width: 100%;
    height: 17px;
}

.calendarBody {
    display: flex;
    flex-direction: column;
    padding-top: .5em;
    margin-top: .5em;
    width: 100%;
    height: 100%;
    /* border: 1px solid #0D0F11; */
}